import React, { useContext, Key, useState, useEffect } from 'react';
import {UserContext}  from './UserProvider';
import { RouteDefines } from './routes';
import {useHistory, useLocation} from 'react-router-dom'
import {doAuhenticatedTenantGet} from './AxiosWrapper';
import Card_HouseHoldHelper, {DisplayMode} from './Card_HouseHoldHelper'
import { Table, Input, Button, Checkbox, DatePicker, Alert, Dropdown, Menu, Form, Space, Select, TimePicker, AutoComplete  } from 'antd';

import {TablePaginationConfig} from 'antd/es/table';
import { MenuInfo } from 'rc-menu/lib/interface';

import {IHouseHoldHelper} from './Models/HouseHoldHelper'
import {doAuhenticatedTenantPost} from './AxiosWrapper';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import {IWorkLocation} from './Models/WorkLocation'

const { Option } = Select;


enum Weekday {
    Sunday,
    Monday,
    Tuesday,
    Wednesday,
    Thursday,
    Friday,
    Saturday
} 

interface RecurringPlanningItem
{
    weekDay:Weekday;
    startHour:number;
    startMinute:number;
    endHour:number;
    endMinute:number;
    duration:number;
    everyWeeks:number;
    workLocationId:number;
}
interface FormListShizzle {

}


//for the testing of the dropdown
interface bananaResult {
    totalCount:number;
    data: IWorkLocation[]
}

interface queryParamsForGet {
    text:string;
	count: number
}
//



const Page_Work_Planner = () => {
    //const history = useHistory();
    const userInfo = useContext(UserContext);
    const [recurringPlanning, setRecurringPlanning] = useState<RecurringPlanningItem[]>([{weekDay:Weekday.Monday, startHour:9, startMinute:0, endHour:12, endMinute:0, duration:3, everyWeeks:1, workLocationId:666}]);

    useEffect(()=> {
        //getData(tableState.pagination.current as number, tableState.pagination.pageSize as number);
    }, []);

    const [value, setValue] = useState('');
    const [options, setOptions] = useState<{ value: string }[]>([]);
    const onSearch = (searchText: string) => {
        if (!searchText||searchText.length<3)
          {
            setOptions([]);
          }
          else
          {
              // todo - get a delay here to prevent too much spamming (eg 200 ms)
            getData(searchText);
          }
      /*setOptions(
        !searchText||searchText.length<3 ? [] : [mockVal(searchText), mockVal(searchText, 2), mockVal(searchText, 3)],
      );*/
    };
    const onSelect = (data: string) => {
      console.log('onSelect', data);
    };
    const onChange = (data: string) => {
      setValue(data);
    };
    const mockVal = (str: string, repeat: number = 1) => ({
        value: str.repeat(repeat),
      });


      const renderItem = (workLocation:IWorkLocation) => ({
        value: workLocation.id.toString(),
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {workLocation.street}
            <span>
              testje
            </span>
          </div>
        ),
      });

      const getDisplayString = (workLocation:IWorkLocation):string => {
          let text:string = '';
          workLocation.serviceUsers.map(su => {
            text += `${su.firstName} ${su.lastName} ${su.emailAddress} `;
          });

          text += `${workLocation.id}`;

          

          return text;
      }

      const getData = async (searchText:string)=> {

        try {
            let a:queryParamsForGet = 
            {
                text: searchText,
                count: 20
            }
            let result = await doAuhenticatedTenantGet<bananaResult>(userInfo.currentTenandId, "GetLocationsByName", a);
            let dataArray = result.data.map(t => {return {value: getDisplayString(t)}}); //return is important here to be seen as a code block
            //let dataArray = result.data.map(t => {return renderItem(t)}); //return is important here to be seen as a code block
            
            setOptions(dataArray);

            
            
        } catch (error) {
            //todo
        }
    }



    if (!userInfo.isRegistered || !userInfo.loggedIn) {
        return <div>User should be registered first.</div>
    }
    if (!userInfo.isEmployee) {
        return <div>User is not an employee.</div>
    }


    const onFinish = (values:any) => {
        console.log('Received values of form:', values);
      };
    

    return (
        <div>
             <AutoComplete
                value={value}
                options={options}
                style={{ width: 300 }}
                onSelect={onSelect}
                onSearch={onSearch}
                onChange={onChange}
                placeholder="Select worklocation"
            />
            <div>Maximum hours per week: 38</div>
            <div>Current planning: na</div>
            TODO - planshizzle
            <Form initialValues={{}} name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
                <Form.List name="RecurringPlanningItems">
                    {(fields, { add, remove }) => (
                    <>
                        {fields.map(field => (
                        <Space key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                            <Form.Item 
                            {...field}
                            name={[field.name, 'weekDay']}
                            fieldKey={[field.fieldKey, 'weekDay']}
                            rules={[{ required: true, message: 'Missing weekday' }]}
                            >
                            <Select style={{width:120}}>
                                <Option value="1">Monday</Option>
                                <Option value="2">TuesDay</Option>
                                <Option value="3">Wednesday</Option>
                                <Option value="4">Thursday</Option>
                                <Option value="5">Friday</Option>
                                <Option value="6">Saturday</Option>
                            </Select>
                            </Form.Item>
                            <Form.Item
                            {...field}
                            name={[field.name, 'startTime']}
                            fieldKey={[field.fieldKey, 'startTime']}
                            rules={[{ type:'object', required: true, message: 'Missing start time'} ]}
                            
                            >
                            <TimePicker defaultValue={moment('08:30', "HH:mm")} format={"HH:mm"} />
                            </Form.Item>
                            <Form.Item
                            {...field}
                            name={[field.name, 'endTime']}
                            fieldKey={[field.fieldKey, 'endTime']}
                            rules={[{ type:'object', required: true, message: 'Missing end time'} ]}
                            
                            >
                            <TimePicker defaultValue={moment('16:30', "HH:mm")} format={"HH:mm"} />
                            </Form.Item>

                            <Form.Item
                            {...field}
                            name={[field.name, 'recurrence']}
                            fieldKey={[field.fieldKey, 'recurrence']}
                            rules={[{ required: true, message: 'Missing recurrance' }]}
                            initialValue="1"
                            
                            >
                            <Input placeholder="Recurrence" />
                            </Form.Item>

                            <Form.Item
                            {...field}
                            name={[field.name, 'workLocationId']}
                            fieldKey={[field.fieldKey, 'workLocationId']}
                            rules={[{ required: true, message: 'Missing work location' }]}
                            initialValue="666"
                            
                            >
                            <Input placeholder="Worklocation id" />
                            </Form.Item>

                            <MinusCircleOutlined onClick={() => remove(field.name)} />
                        </Space>
                        ))}
                        <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add recurring item
                        </Button>
                        </Form.Item>
                    </>
                    )}
                </Form.List>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                    Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default Page_Work_Planner;