import React, { useContext, ChangeEvent } from 'react';
import {UserContext}  from './UserProvider';
import { RouteDefines } from './routes';
import {useHistory, useLocation} from 'react-router-dom'
import {doAuhenticatedTenantPost} from './AxiosWrapper';
import { Form, Input, Button, Checkbox } from 'antd';
import {IHouseHoldHelper} from './Models/HouseHoldHelper'


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
    }
  };
  
export enum DisplayMode {
    Read,
    AddNew,
    Update
}

export interface CardProps {
    DisplayMode:DisplayMode
}  

 const Card_HouseHoldHelper = (props:CardProps) => {
    const history = useHistory();
    const userInfo = useContext(UserContext);
    const [form] = Form.useForm();

    const onAdd = async () => {
        
    }


    const onFinish = async (values:any) => {
        console.log(values);
        await doAuhenticatedTenantPost<IHouseHoldHelper>(userInfo.currentTenandId, "CreateHouseHoldHelper", values.houseHoldHelper);
        /*try
        {
          let res = await axios.post(config.backEndApi + '/api/Contact', values);
          setPostFeedback('Uw aanvraag werd verstuurd.');
          form.resetFields();
        }
        catch{
          setPostFeedback('Uw aanvraag kon niet verstuurd worden');
        }*/
    };


    if (!userInfo.isRegistered || !userInfo.loggedIn) {
        return <div>User should be registered first.</div>
    }
    if (!userInfo.isEmployee) {
        return <div>User is not an employee.</div>
    }

    return (
        <div>
             HouseHoldHelperCard
             <br/>
             <br/>
             <br/>
             <Form form={form} {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
              <Form.Item name={['houseHoldHelper', 'firstName']} label="FirstName" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name={['houseHoldHelper', 'lastName']} label="LastName" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name={['houseHoldHelper', 'emailAddress']} label="Email" rules={[{ type: 'email' }]}>
                <Input />
              </Form.Item>
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
        </div>
    );
}

export default Card_HouseHoldHelper;