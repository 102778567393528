import React, { useState, useEffect  } from 'react';


import { Console } from 'console';

const Page_Legal_Privacy = () => {

    return (
        <div>
          <div>
            This site collects some personal information from the user. This page describes how this information is used.
            </div>
            <br/><br/><br/><br/>
            <h2>Information purpose</h2>
            Cormaps.com can process data about you, by using it's services and/or because you submitted the contact form. This information includes the contact form information as well as your ip-address. This data is use to contact you or to do usage-analysis based on the ip-address to improve our website.
            <br/><br/><br/><br/>
            <h2>Access to information</h2>
            Your personal information is stored in a secure way. It is not shared with third parties. Our co-corkers can access the information only to perform their tasks. In case -despite our security measurements- a breach is detected, you will be informed.
            <br/><br/><br/><br/>
            <h2>Request information</h2>
            You have the right to request your personal information we store about you. You can have the information changed or deleted by us. In case you want to request/delete the information, please contact us.
            <br/><br/><br/><br/>
            
        </div>
    );
}

export default Page_Legal_Privacy;
;