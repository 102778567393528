import { Console } from 'console';
import {UserAgentApplication, Configuration, Account, InteractionRequiredAuthError, AuthenticationParameters, AuthResponse, AuthError} from "msal";
import  config  from './config';

//https://docs.microsoft.com/en-us/azure/active-directory-b2c/tutorial-single-page-app?tabs=app-reg-auth%2Cconfig-auth%2Creview-auth
const configuration:Configuration = {
    auth: {
      clientId: config.b2cClientId,
      authority: `https://${config.b2cTenantName}.b2clogin.com/${config.b2cTenantName}.onmicrosoft.com/B2C_1_UserFlow1`,
      validateAuthority: false
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
    }
  
  }
  
  export const loginRequest = {
    scopes: ["openid", "profile"],
  };
  
  // Add here scopes for access token to be used at the API endpoints.
  const tokenRequest:AuthenticationParameters = {
    
    scopes: [`https://${config.b2cTenantName}.onmicrosoft.com/faapi/Hello`]
  };
  
  //singleton is ok 
  export const myMSALObj = new UserAgentApplication(configuration);


  export let myMSALErrorOccurred:boolean = false;
  export let myMSALErrorMessage:string = "";




const myTokenReceivedCallback = (response: AuthResponse):void => {
  myMSALErrorOccurred = false;
  myMSALErrorMessage = "";
  console.info(response);
}

const myErrorReceivedCallback = (authErr: AuthError, accountState: string):void =>{
  myMSALErrorOccurred = true;
  myMSALErrorMessage = authErr.errorMessage;
  console.info(authErr);
}

myMSALObj.handleRedirectCallback(myTokenReceivedCallback, myErrorReceivedCallback);

export const resetMSALError = ()=> {
  myMSALErrorOccurred = false;
  myMSALErrorMessage = "";
}





  export const AquireToken = async ():Promise<string>=> {
    try{
        let response = await  myMSALObj.acquireTokenSilent(tokenRequest);
        if (!response.accessToken || response.accessToken === "") {
            throw new InteractionRequiredAuthError("MISSING_ACCESSTOKEN", "Response had no access token");
        }
        return response.accessToken;
    } catch(error){
        if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            try{
                let response = await myMSALObj.acquireTokenRedirect(tokenRequest); 
            }
            catch(anotherError) {
                console.log(anotherError);
            }
        } else {
            console.log(error);
        }
    }
    return "";
  }
  