import React, { useState, useEffect  } from 'react';
import {sbGetTimePart, sbGetDatePart, sbWeekDayPart} from './DateFormatter'

export interface IWorkSessionCardProps {
    Id:number;
    From:Date;
    To:Date;
    Description:string;
    Street: string;
    Approved:boolean;
}

const WorkSessionCard = (props:IWorkSessionCardProps) => {
let backGroundColor = props.Approved?"lightblue":"lightGrey";

return <div style ={{minHeight:"30px", backgroundColor:backGroundColor, marginBottom:"1rem"}}>
    <div><b>{sbWeekDayPart(props.From)} {sbGetDatePart(props.From)} {sbGetTimePart(props.From)}-{sbGetTimePart(props.To)}</b></div>
    <div style={{textAlign:"left"}}>{props.Description}</div>
    <div style={{textAlign:"left",  fontSize:"10px"}}>
        <div>{props.Street} 6666</div>
        <div>9800 Deinze</div>
        <div>0494/60.05.73</div>
    </div>
</div>

}

export default WorkSessionCard;