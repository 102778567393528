import React, { useContext, ChangeEvent } from 'react';
import {UserContext}  from './UserProvider';
import { RouteDefines } from './routes';
import {useHistory, useLocation} from 'react-router-dom'
import {doAuhenticatedTenantPost} from './AxiosWrapper';
import { Form, Input, Button, Checkbox } from 'antd';
import {IWorkLocation} from './Models/WorkLocation'


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  
  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not validate email!',
    }
  };
  
export enum DisplayMode {
    Read,
    AddNew,
    Update
}

export interface CardProps {
    DisplayMode:DisplayMode
}  

 const Card_WorkLocation = (props:CardProps) => {
    const history = useHistory();
    const userInfo = useContext(UserContext);
    const [form] = Form.useForm();

    const onAdd = async () => {
        
    }


    const onFinish = async (values:any) => {
        console.log(values);

        let workLocation:IWorkLocation =  {
          id: -1, 
          /*address: {
            description:'',
            postalCode: values.workLocation.postalCode,
            city: values.workLocation.city,
            street: values.workLocation.street,
            number: values.workLocation.number,
            country: '',
            longitude: 0,
            latitude:0
          },*/
          city:values.workLocation.city,
          postalCode: values.workLocation.postalCode,
          street: values.workLocation.street,
          houseNumber:values.workLocation.number,
          box:'',
          serviceUsers: [{
            id:-1,
            firstName: values.workLocation.firstName, 
            lastName: values.workLocation.lastName,
            emailAddress: values.workLocation.emailAddress,
            sodexoUserId: values.workLocation.sodexoUserId
          }]
      }
      console.log(workLocation);

        await doAuhenticatedTenantPost<IWorkLocation>(userInfo.currentTenandId, "CreateTenantWorkLocation", workLocation);
        /*try
        {
          let res = await axios.post(config.backEndApi + '/api/Contact', values);
          setPostFeedback('Uw aanvraag werd verstuurd.');
          form.resetFields();
        }
        catch{
          setPostFeedback('Uw aanvraag kon niet verstuurd worden');
        }*/
    };


    if (!userInfo.isRegistered || !userInfo.loggedIn) {
        return <div>User should be registered first.</div>
    }
    if (!userInfo.isEmployee) {
        return <div>User is not an employee.</div>
    }

    return (
        <div>
             WorkLocationCard
             <br/>
             <br/>
             <br/>
             <Form form={form} {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
              <Form.Item name={['workLocation', 'postalCode']} label="Postal code" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name={['workLocation', 'city']} label="City" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name={['workLocation', 'street']} label="Street" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name={['workLocation', 'number']} label="Number" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name={['workLocation', 'firstName']} label="First name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name={['workLocation', 'lastName']} label="Last name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name={['workLocation', 'emailAddress']} label="Email" rules={[{ type: 'email' }]}>
                <Input />
              </Form.Item>
              <Form.Item name={['workLocation', 'telephone']} label="Telephone" rules={[]}>
                <Input />
              </Form.Item>
              <Form.Item name={['workLocation', 'sodexoUserId']} label="Sodexo user id" rules={[]}>
                <Input />
              </Form.Item>
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
        </div>
    );
}

export default Card_WorkLocation;