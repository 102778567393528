import React, { useState, useEffect  } from 'react';
import { Console } from 'console';
import {UserAgentApplication, Configuration, Account, InteractionRequiredAuthError, AuthenticationParameters, AuthResponse} from "msal";
import  config  from './config'; 
import axios, {AxiosRequestConfig, AxiosResponse,} from 'axios';
import {IHelloDto} from './Models/HelloDto';
import {IWorkLocationModel, IWorkSessionModel} from './Models/HelloModels'
import {myMSALObj, loginRequest, AquireToken} from './MsalWrapper'

import WorkSessionCalender from './WorkSessionCalender'
import {IWorkSessionCardProps} from './WorkSessionCard';
import {UserContext}  from './UserProvider';



const Page_Work = () => {
  const [workSessions2, setWorksessions2] = useState<IWorkSessionCardProps[]>([])
  
  const userInfo = React.useContext(UserContext);

  
  useEffect(() => {
  }, []);

  const buttonClicked =  async () => {
    console.info("clicked");

    //if (account === undefined) {
      myMSALObj.loginRedirect(loginRequest);
     //myMSALObj.loginPopup(loginRequest);
    //}
  }

  
  const callApibuttonClicked =  async () => {
    console.info("clicked");

    var axiosRequestConfig:AxiosRequestConfig = {};

    if (userInfo.loggedIn) {
      var accessToken = await AquireToken();
      if (accessToken === "")
      {
        console.log("Failed to get accesstoken");
        return;
      }
      console.log("access_token acquired at: " + new Date().toString());

      axiosRequestConfig.headers= {
          Authorization: `Bearer ${accessToken}`
          //Authorization: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsifQ.eyJpc3MiOiJodHRwczovL3NlcnZpY2VidW5ueWIyY2Rldi5iMmNsb2dpbi5jb20vMjIxMjQ1ZDAtN2JlZC00MWMyLThlY2YtMjBmM2NkNjNkODJkL3YyLjAvIiwiZXhwIjoxNjA2NjYzNDA1LCJuYmYiOjE2MDY2NTk4MDUsImF1ZCI6ImY1MjI4NzQ0LTc1YWYtNDdhMy1iOWUxLWJiZjhiOWYzYzlkOCIsIm9pZCI6IjFjNTFhZTM1LWIyOGYtNGIwMC04NDZlLWY3YjI2ZmIwZjIxMCIsInN1YiI6IjFjNTFhZTM1LWIyOGYtNGIwMC04NDZlLWY3YjI2ZmIwZjIxMCIsImVtYWlscyI6WyJrdXJ0eUBwYW5kb3JhLmJlIl0sInRmcCI6IkIyQ18xX1VzZXJGbG93MSIsIm5vbmNlIjoiYzljZTY5YTAtNjUxYi00NzA0LTg1YTAtMmFlNTUyMGVkMTFiIiwic2NwIjoiSGVsbG8iLCJhenAiOiIxMTc2YTE0MC02ODIwLTQxNTQtODg0NC02N2ZiZTU3OWRhYjYiLCJ2ZXIiOiIxLjAiLCJpYXQiOjE2MDY2NTk4MDV9.UOxUsyuOUkhG8V62E-2TlqXSVzDTO_L59Rq6SJolJrytF37zQMacLzd1nKNem9J-R9aAsl_n9g1oeAmllASFAd9w5ZNXttpI9EDY5FD5UmQZk598jo3miEyYw4jo4WFVUdMzmkHwAVtzvhwCBR7nnVudKBNdhgXKGDYFnKwOCQFqGtHRPNP6WRvCPT-XTe9_vZcuPUh1pTzNYYywnStKL17Htf4FYikyZFLfl4Qes5Y1BMjj3FHL8Nkmw74Gu1OGEDGILLUY1RlPaDjXlOJtAlAerfVAFxArWUrM_N1zHNlOdTL35PjLCy1XFYPYxhStXqUZZWty2AlVczRT_ByBkg'
        }
    }

    try
    {
      let res = await axios.get(config.backEndApi + '/api/hello', axiosRequestConfig);
      var a = res.data as IHelloDto;
      console.info(JSON.stringify(a));
      CreateUsableObject(a);
    }
    catch (error){
      console.info('hello call failed')
    }
  }


  const CreateUsableObject = (data:IHelloDto) => {

    let items:IWorkSessionCardProps[] = [];

    data.workSessions.map((session:IWorkSessionModel, index:number)=>
              {
                var workLocation = data.relevantWorkLocations.filter((item:IWorkLocationModel) => {
                  return item.id === session.workLocationId
                })[0] || null; //firstordefault
                items.push({
                  Id:session.id,
                  From:new Date(Date.parse(session.from)),
                  To:new Date(Date.parse(session.to)),
                  Description:workLocation?.name,
                  Street: workLocation?.street,
                  Approved:session.approved
                });
                
              });
    setWorksessions2(items); // no need to spread it, it is a new array

  }

  

    return (
      <div>Work
        <button onClick={buttonClicked}>Log in</button>
        <br/>
        {userInfo.loggedIn ?  <div>ha - {userInfo.userName}</div>:<div></div>}
        <br/>
        <button onClick={callApibuttonClicked}>CallApi</button>
        <br/>
        <br/>
        <WorkSessionCalender workSessions={workSessions2}/>
      </div> 
    );
}

export default Page_Work;