import { Console } from 'console';

const doubleDigit = (digits:number):string =>
{
    return `${digits}`.padStart(2, '0');
}

export const sbGetTimePart = (theDate:Date):string =>
{
    return  `${doubleDigit(theDate.getHours())}:${doubleDigit(theDate.getMinutes())}`
}

export const sbGetDatePart = (theDate:Date):string =>
{
    return  `${doubleDigit(theDate.getDate())}/${doubleDigit(theDate.getMonth())}/${theDate.getFullYear()}`
}

export const sbWeekDayPart = (theDate:Date):string =>
{
    switch(theDate.getDay())
    {
        case 0: return 'Sunday'
        case 1: return 'Monday'
        case 2: return 'Tuesday'
        case 3: return 'Wednesday'
        case 4: return 'Thursday'
        case 5: return 'Friday'
        case 6: return 'Saturday'
    };
    return ('no, it is impossible');
}