//ProtectedRoute 
import React, {useState, useEffect, createContext}  from 'react';
import {UserContext}  from './UserProvider';
import { Redirect, Route, useLocation, RouteProps } from 'react-router-dom'
import { RouteDefines } from './routes';

//import { Console } from 'console';


//TODO
// https://blog.netcetera.com/how-to-create-guarded-routes-for-your-react-app-d2fe7c7b6122
// https://gist.github.com/safx/da7c0d1d23b582013f082a6d1b947117
// https://dev.to/ibrahimawadhamid/how-to-create-a-private-route-in-react-route-guard-example-for-authenticated-users-only-kin

/*interface IProtectedRouteProps{
    component: React.FC; 
    path: string;
    exact: boolean;
}*/

export const ProtectedRoute = (props:RouteProps) => {
    const userInfo = React.useContext(UserContext);
    const location = useLocation();

    console.info(location.pathname);

    const needsRegistrationFirst =  (location.pathname !== RouteDefines.ROUTE_REGISTER) &&
                                    (location.pathname !== RouteDefines.ROUTE_AUTO_REGISTER_USER) && 
                                    userInfo.loggedIn && 
                                    !userInfo.isRegistered;

    console.info('needsRegistrationFirst?', needsRegistrationFirst);

        
        return  needsRegistrationFirst ? 
            (<Redirect  to={RouteDefines.ROUTE_REGISTER}  />) : 
            (<Route  
                location={props.location} 
                component={props.component} 
                render={props.render} 
                children={props.children} 
                path={props.path} 
                exact={props.exact} 
                sensitive={props.sensitive} 
                strict={props.strict}  />)

}
