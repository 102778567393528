import React, { useState, useEffect, useContext  } from 'react';
import {useHistory, useLocation} from 'react-router-dom'
import { Console } from 'console';

import { Menu, Avatar} from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { MailOutlined, EnvironmentOutlined, WarningOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { RouteDefines } from './routes';
import {UserContext}  from './UserProvider';
import {myMSALObj, loginRequest, AquireToken, myMSALErrorOccurred, myMSALErrorMessage, resetMSALError} from './MsalWrapper'

const CMD_SHOWACCOUNT:string = "showaccount";
const CMD_LOGOUT:string = "logout";

const MainMenu = () => {
    
    const history = useHistory();
    const location = useLocation();
    const [menuItemSelection, setMenuItemSelection] = useState('');
    const userInfo = useContext(UserContext);


    useEffect(() => {
      console.log('location shizzle', location.pathname);
      var route = location.pathname;
      if (route.length===0 || route ==='/') {
        setMenuItemSelection(RouteDefines.ROUTE_HOME);
      }
      else if (route === RouteDefines.ROUTE_CONTACT){
        setMenuItemSelection(route);
      } else {
        setMenuItemSelection('');
      }       
      
    }, [location]);

    function handleClick(info:any) {
      console.info('click received', info);

      if (info.key === CMD_LOGOUT) {
        myMSALObj.logout();
      }
      else if (info.key === CMD_SHOWACCOUNT) {
        history.push(RouteDefines.ROUTE_ACCOUNT)
      }
      /*if (info.key === RouteDefines.ROUTE_HOME) {
        history.push('');
      } else {
        history.push(info.key);
      }
      setMenuItemSelection (info.key);*/
    };     
    //{userInfo.loggedIn?userInfo.userName:"Login"}

    return (
      <div style={{ display: "flex" }}>
        <Menu onClick={handleClick} selectedKeys={[menuItemSelection]} mode="horizontal" style={{ marginLeft: "auto"  }}>
          <Menu.Item key={RouteDefines.ROUTE_HOME} icon={<EnvironmentOutlined />} >
            Home
          </Menu.Item>
          <Menu.Item key={RouteDefines.ROUTE_CONTACT} icon={<MailOutlined />}>
            Contact
          </Menu.Item>
          <Menu.SubMenu  key="Logout" icon={<UserOutlined />}>
            <Menu.Item key={CMD_SHOWACCOUNT} > Account </Menu.Item>
            <Menu.Item key={CMD_LOGOUT} > Log out </Menu.Item>
          </Menu.SubMenu >
        </Menu>
      </div> 
    );
}

export default MainMenu;