import React, {useState, useEffect, createContext, ReactNode, useCallback}  from 'react';
import {myMSALObj, loginRequest, AquireToken, myMSALErrorOccurred, myMSALErrorMessage, resetMSALError} from './MsalWrapper'
import  config  from './config'; 
import axios, {AxiosRequestConfig, AxiosResponse,} from 'axios';
import {IHelloDto} from './Models/HelloDto';
import {useHistory, useLocation} from 'react-router-dom'


interface IUserContext
{
  loggedIn:boolean;
  userName:string;
  isRegistered:boolean;
  currentTenandId:number;
  isHouseHoldHelper:boolean;
  isEmployee:boolean;
  setUserData: (currentTenandId:number, isHouseHoldHelper:boolean, isEmployee:boolean)=>void
}

interface IUserState{
  username:string;
  loggedOn:boolean;
  isRegistered:boolean;
  currentTenandId:number;
  isHouseHoldHelper:boolean;
  isEmployee:boolean;
}

export const UserContext = createContext<IUserContext>({loggedIn:false, userName: '', isRegistered:false, currentTenandId:-1, isHouseHoldHelper:false, isEmployee:false, setUserData:(currentTenandId:number, isHouseHoldHelper:boolean, isEmployee:boolean)=>null});

interface UserProviderprops{
    children:React.ReactNode
}

interface IErrorData{
  error:boolean,
  errorMessage:string
}


export const UserProvider = (props:UserProviderprops ) => {
    // User is the name of the "data" that gets stored in context
    const [userState, setUserState] = useState<IUserState>({loggedOn:false, username:'', isRegistered:false, currentTenandId:-1, isHouseHoldHelper:false, isEmployee:false});
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<IErrorData>({error: false, errorMessage:""});
    
    const location = useLocation();

    const setUserData = useCallback((currentTenandId:number, isHouseHoldHelper:boolean, isEmployee:boolean) => {
      //console.info(userState)
      setUserState({...userState, isRegistered:true, currentTenandId:currentTenandId, isHouseHoldHelper:isHouseHoldHelper, isEmployee:isEmployee}) 
      
    }, [userState, setUserState]);

    useEffect( () => {
        const fetchData = async () => {
          try
          {
            var accessToken = await AquireToken();
            if (accessToken === "")
            {
                console.log("Failed to get accesstoken");
                setLoading(false);
                setError({error:true, errorMessage:"Failed to get accesstoken"});
                return;
            }
            console.log("access_token acquired at: " + new Date().toString());
            var axiosRequestConfig:AxiosRequestConfig = {};
            axiosRequestConfig.headers= {
                Authorization: `Bearer ${accessToken}`
                }
    
            
                let res = await axios.get(config.backEndApi + '/api/hello', axiosRequestConfig);
                var a = res.data as IHelloDto;
                setUserState({...userState, loggedOn:true, username:a.displayName, isRegistered:a.knownUser, currentTenandId:a.defaultTenantId, isHouseHoldHelper:a.isHouseHoldHelper, isEmployee:a.isEmployee}) 
                setLoading(false);
                
          }
            catch (error){
              console.info('hello call failed');
              setLoading(false);
              setError({error:true, errorMessage:`Hello call failed: ${error}`});
              return;
          }
        } //end async hack

        if (myMSALErrorOccurred)
        {
          setLoading(false);
          setError({error:true, errorMessage:`Authentication failed ${myMSALErrorMessage}`});
          resetMSALError(); //set ready for retry
          return; //done here
        }
         
        const account = myMSALObj.getAccount();

        if (account !== undefined && account !== null) {

          //myMSALObj.logout();
          //var defaultName = JSON.stringify(account.idTokenClaims);
            var defaultName = account.idTokenClaims["emails"][0];
            fetchData();
        } else {
          // do a wakeup call to warmup the Azure functions (but do not wait for the result)
          axios.get(config.backEndApi + '/api/wakeup');
          myMSALObj.loginRedirect(loginRequest);
        }
    
      }, []);

      if (loading === true) {
        return <div>Getting user information...</div>
      }

      if (error.error === true) {
        return <div> 
          <div>There was a problem during the login process. Please try again later.</div><br/><br/><br/>
          <div style={{fontSize:10}}>
          {error.errorMessage}</div>
        </div>
      }

    return (
      <UserContext.Provider value={{
        loggedIn:userState.loggedOn,
        userName:userState.username,
        isRegistered:userState.isRegistered,
        currentTenandId:userState.currentTenandId,
        isHouseHoldHelper:userState.isHouseHoldHelper,
        isEmployee:userState.isEmployee, setUserData:setUserData   
      }}>
        {props.children}
      </UserContext.Provider>
    );
  }