import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

/*authentication.initialize({
    // optional, will default to this
    instance: 'https://login.microsoftonline.com/tfp/', 
    // your B2C tenant
    tenant: 'servicebunnyb2cdev.onmicrosoft.com',
    // the policy to use to sign in, can also be a sign up or sign in policy
    signInPolicy: 'B2C_1_UserFlow1',
    // the the B2C application you want to authenticate with (that's just a random GUID - get yours from the portal)
    clientId: '1176a140-6820-4154-8844-67fbe579dab6', //*
    // where MSAL will store state - localStorage or sessionStorage
    cacheLocation: 'localStorage',
    // the scopes you want included in the access token
    scopes: ['https://servicebunnyb2cdev.onmicrosoft.com/management/admin'],
    // optional, the redirect URI - if not specified MSAL will pick up the location from window.href
    redirectUri: 'http://localhost:3000'
});
*/

//authentication.run(() => {
///  ReactDOM.render(
    ///<React.StrictMode>
    //<App />
  //</React.StrictMode>
  //, document.getElementById('root'));
//});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
