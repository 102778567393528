
 
export class RouteDefines {
    public static readonly ROUTE_LEGAL_COOKIES:string = "/legal/cookies";
    public static readonly ROUTE_LEGAL_PRIVACY:string = "/legal/privacy";
    public static readonly ROUTE_HOME:string = "/home";
    public static readonly ROUTE_CONTACT:string = "/contact";
    public static readonly ROUTE_SOLUTION:string = "/solutions";
    public static readonly ROUTE_WORK:string = "/work";
    public static readonly ROUTE_REGISTER = "/register";
    public static readonly ROUTE_AUTO_REGISTER_USER = "/autoregisteruser"
    public static readonly ROUTE_WORK_HOUSEHOLDHELPER = "/householdhelper"
    public static readonly ROUTE_WORK_WORKLOCATION = "/worklocation"
    public static readonly ROUTE_WORK_PLANNER = "/planner"
    public static readonly ROUTE_WORKLOCATION_PLANNER = "/worklocationplanner"
    public static readonly ROUTE_ACCOUNT = "/account"
    
    
 }
