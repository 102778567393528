//import { exception } from 'console';
import  config  from './config'; 
import axios, {AxiosRequestConfig} from 'axios';
import {myMSALObj, loginRequest, AquireToken} from './MsalWrapper'

export const doAuhenticatedPost =  async <T>(endPoint:string, payload:any):Promise<T> => {

    var accessToken = await AquireToken();
    if (accessToken === "")
    {
        console.log("Failed to get accesstoken");
        throw Error("Failed to get accesstoken");
    }
    console.log("access_token acquired at: " + new Date().toString());
    var axiosRequestConfig:AxiosRequestConfig = {};
    axiosRequestConfig.headers= {Authorization: `Bearer ${accessToken}`}

    let res = await axios.post(config.backEndApi + `/api/${endPoint}`, payload, axiosRequestConfig);
    return res.data as T;
}


export const doAuhenticatedTenantPost =  async <T>(tenantId:number, endPoint:string, payload:any):Promise<T> => {

    var accessToken = await AquireToken();
    if (accessToken === "")
    {
        console.log("Failed to get accesstoken");
        throw Error("Failed to get accesstoken");
    }
    console.log("access_token acquired at: " + new Date().toString());
    var axiosRequestConfig:AxiosRequestConfig = {};
    axiosRequestConfig.headers= {Authorization: `Bearer ${accessToken}`, TenantId:tenantId}

    let res = await axios.post(config.backEndApi + `/api/${endPoint}`, payload, axiosRequestConfig);
    return res.data as T;
}

export const doAuhenticatedTenantGet =  async <T>(tenantId:number, endPoint:string, params:any=null):Promise<T> => {

    var accessToken = await AquireToken();
    if (accessToken === "")
    {
        console.log("Failed to get accesstoken");
        throw Error("Failed to get accesstoken");
    }
    console.log("access_token acquired at: " + new Date().toString());
    var axiosRequestConfig:AxiosRequestConfig = {};
    axiosRequestConfig.headers= {Authorization: `Bearer ${accessToken}`, TenantId:tenantId}
    axiosRequestConfig.params = params;

    let res = await axios.get(config.backEndApi + `/api/${endPoint}`, axiosRequestConfig);
    return res.data as T;
}