import React, {useState, useEffect, createContext}  from 'react';
import logo from './logo.svg';
import './App.css';


import CookieConsent, { Cookies } from "react-cookie-consent";

import MainMenu from './MainMenu'
import MainFooter from './MainFooter'
import Page_Home from './Page_Home';
import Page_Contact from './Page_Contact';
import Page_Legal from './Page_Legal';
import Page_Work from './Page_Work';
import Page_Register from './Page_Register';
import Page_AutoRegisterUser from './Page_AutoRegisterUser';
import Page_Work_HouseHoldHelper from './Page_Work_HouseHoldHelper';
import Page_Work_WorkLocation from './Page_Work_WorkLocation';
import Page_Work_Planner from './Page_Work_Planner';
import Page_Account from './Page_Account';


import {Route, BrowserRouter , Switch, Redirect, Link} from 'react-router-dom'
import { RouteDefines } from './routes';
import {UserProvider}  from './UserProvider';
import {ProtectedRoute} from './ProtectedRoute'


enum Language {
  En = "en-US",
  Nl = "nl-BE"
}


function App() {

  const cookieNameConsent = 'CookieConsent';
  const cookieNameConsentLegacy = 'CookieConsent-legacy';

  const [cookiesConsentGiven, setcookiesConsentGiven] = useState(Cookies.get(cookieNameConsent) === 'true' || Cookies.get(cookieNameConsentLegacy) === 'true');
  


  let language:Language = Language.Nl;
  
  //todo move this, very clean- https://dev.to/curtiscodes/learn-to-usecontext-with-hooks-in-3-minutes-4c4g
  useEffect(() => {

  }, []);

  //<Route path="/"     exact render={(props) => <div>}   />
  //<Route path={RouteDefines.ROUTE_MAPS} exact render={(props) => <Page_Maps cookiesConsentGiven={cookiesConsentGiven} language={language}  {...props}/>}   />
   //<Route path={RouteDefines.ROUTE_CONTACT} exact component={Page_Contact} />

   //<Route path={RouteDefines.ROUTE_CONTACT} exact component={authentication.required(Page_Contact)}/>
  
//TODO craete protected route - https://medium.com/javascript-in-plain-english/how-to-set-up-protected-routes-in-your-react-application-a3254deda380


//<Redirect from='*' to='/' />

  return (
    <BrowserRouter>
      <UserProvider>
        <div className="App" style={{ width:'100vw', margin:'auto', maxWidth:'960px'  }}>
          <CookieConsent  /*https://www.npmjs.com/package/react-cookie-consent*/ 
            location="top"
            cookieName={cookieNameConsent}
            onAccept={() => {
              setcookiesConsentGiven(true);
            }} >This website uses cookies to enhance the user experience.</CookieConsent>
          <MainMenu/>
          <br/>
          <div style={{minHeight:'80vh'}}>
          <Switch>
            <ProtectedRoute path="/" exact component={Page_Home} />
            <ProtectedRoute path={RouteDefines.ROUTE_HOME} exact component={Page_Home} />
            <ProtectedRoute path={RouteDefines.ROUTE_CONTACT} exact component={Page_Contact}/>
            <ProtectedRoute path={RouteDefines.ROUTE_WORK} exact component={Page_Work}/>
        
            <ProtectedRoute path="/legal" exact component={Page_Legal} />
            <ProtectedRoute path={RouteDefines.ROUTE_LEGAL_COOKIES} exact component={Page_Legal} />
            <ProtectedRoute path={RouteDefines.ROUTE_LEGAL_PRIVACY} exact component={Page_Legal} />
            <ProtectedRoute path={RouteDefines.ROUTE_REGISTER} exact component={Page_Register} />
            <ProtectedRoute path={RouteDefines.ROUTE_AUTO_REGISTER_USER} exact component={Page_AutoRegisterUser} />

            <ProtectedRoute path={RouteDefines.ROUTE_WORK_WORKLOCATION} exact component={Page_Work_WorkLocation} />
            <ProtectedRoute path={RouteDefines.ROUTE_WORK_HOUSEHOLDHELPER} exact component={Page_Work_HouseHoldHelper} />
            <ProtectedRoute path={RouteDefines.ROUTE_WORK_PLANNER} exact component={Page_Work_Planner} />
            <ProtectedRoute path={RouteDefines.ROUTE_ACCOUNT} exact component={Page_Account} />
            
          </Switch>
          </div>
          <br/>
          <br/>
          <MainFooter/>
        </div>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
