import React, { useContext } from 'react';
import {UserContext}  from './UserProvider';
import { RouteDefines } from './routes';
import {useHistory, useLocation} from 'react-router-dom'


const Page_Home = () => {
    const history = useHistory();
    const userInfo = useContext(UserContext);

    const onManageHouseHoldHelpersClicked =() => {
        history.push(RouteDefines.ROUTE_WORK_HOUSEHOLDHELPER);
    }
    const onManageWorkLocationsClicked =() => {
        history.push(RouteDefines.ROUTE_WORK_WORKLOCATION);
    }

    if (!userInfo.isRegistered || !userInfo.loggedIn) {
        return <div>User should be registered first.</div>
    }
  
    return (
        <div>
            <i>Welcome {userInfo.userName}</i>.    
            <br/><br/><br/>
            {userInfo.isEmployee && <button onClick={onManageHouseHoldHelpersClicked}>Manage househould helpers</button>}
            {userInfo.isEmployee && <button onClick={onManageWorkLocationsClicked}>Manage work location (service users)</button>}
            {userInfo.isHouseHoldHelper && <div>Householdhelper info comes here.</div>}

        </div>
    );
}

export default Page_Home;