import React, { useState, useEffect, MouseEvent  } from 'react';
import {useHistory, useLocation} from 'react-router-dom'
import { Console } from 'console';

import { Layout } from 'antd';
const { Footer } = Layout;
//const { Link } = Anchor;



const MainFooter = () => {

    const history = useHistory();
    
    function handleClick(e:MouseEvent<HTMLAnchorElement>) {
        e.preventDefault();
        console.info(e.currentTarget.id);
        history.push(e.currentTarget.id);
    };     

    return (
      <div style={{ width: "100%", fontSize:10 }}  >
        <div style={{ textAlign: 'center', display:'flex', justifyContent:'space-evenly', backgroundColor:"#ebf0f5" }}>
          <span>©2021 Kaimonos</span>
          <span style={{}}>
            <a href="/legal/cookies" id="/legal/cookies" onClick={handleClick} className="footerLinks">Cookiebeleid</a>
          </span>
       </div>
    </div> 
    );
}

export default MainFooter;