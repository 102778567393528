import React, { useState, ChangeEvent } from 'react';
import { Console } from 'console';
import  config  from './config'; 
import axios, {AxiosRequestConfig} from 'axios';
import {UserContext}  from './UserProvider';
import {useHistory, useLocation} from 'react-router-dom'

import {myMSALObj, loginRequest, AquireToken} from './MsalWrapper'
import {IHelloDto} from './Models/HelloDto';


const Page_Register = () => {
    const [error, setError] = useState('');
    const [tenantName, setTenantName] = useState('');
    const userInfo = React.useContext(UserContext);
    const history = useHistory();
    
    const onRegisterPressed = async () => {
        
        if (checkTenantName(tenantName)=== false) return;

        try
        {
            var accessToken = await AquireToken();
            if (accessToken === "")
            {
                console.log("Failed to get accesstoken");
                //return undefined;
            }
            //return null;
            console.log("access_token acquired at: " + new Date().toString());
            var axiosRequestConfig:AxiosRequestConfig = {};
            axiosRequestConfig.headers= {Authorization: `Bearer ${accessToken}`}

          let res = await axios.post(config.backEndApi + `/api/createtenant?tenantName=${tenantName}`, {}, axiosRequestConfig);
          var helloResult = res.data as IHelloDto;
          userInfo.setUserData(helloResult.defaultTenantId, helloResult.isHouseHoldHelper, helloResult.isEmployee);
          history.push('');
        }
        catch{
            setError('There was a problem registering your tenant. Please try again.');
        }
      };

      const checkTenantName = (tenantName: string):boolean => {
        let value = tenantName.replace(/\s/g, "");
        
        if (value.length < 3) {
            setError("Tenant should at least be 3 character long");
            return false;
        } else {
            setError("");
            return true;
        } 
      }

      const validateInput = (e:ChangeEvent<HTMLInputElement>)=> {
        setTenantName(e.target.value);
        checkTenantName(e.target.value);
      }

      if (userInfo.isRegistered) {
      return <div>{userInfo.userName} already registered</div>
      }
  
    return (
        <div>
             <h1>Register</h1>. 
             <br/><br/>
             <div><b>You're a household helper?</b><br/> Ask your company to send you an invitation mail.</div>
             <br/><br/>
             <div><b>You're an employee of a company?</b><br/> Ask your company to send you an invitation mail.</div>
             <br/><br/>
             <div><b>You manage a company with one or more acknowledged enterprises?</b><br/> 
             Create a tenant.<br/>
             A tenant can contain multiple acknowledged enterprises, multiple offices, multiple administrative employees, ...
             <br/>
             <label>Enter a name for your tenant : </label><input onChange={validateInput} value={tenantName}></input> <button onClick={onRegisterPressed}>Create tenant</button>
             <div style={{color: "red"}}>{error}</div>
             <br/>

             </div>
             
        </div>
    );
}

export default Page_Register;