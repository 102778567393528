import React, { useContext, useEffect, useState  } from 'react';

import {UserContext}  from './UserProvider';
import {useLocation, useHistory} from 'react-router-dom'

import {myMSALObj, loginRequest, AquireToken} from './MsalWrapper'
import  config  from './config'; 
import {doAuhenticatedPost} from './AxiosWrapper';
import {IHelloDto} from './Models/HelloDto';


const Page_AutoRegisterUser = () => {
    const userInfo = useContext(UserContext);
    const location = useLocation();

    //const [busyRegistering , setBusyRegistering] = useState<boolean>(true);
    const [userFeedBack , setUserFeedBack] = useState<string|null>();
    const history = useHistory();

    const fetchData = async (token:string) => {
        try
        {
            var helloResult = await doAuhenticatedPost<IHelloDto>(`RegisterByToken?token=${token}`, {});
            userInfo.setUserData(helloResult.defaultTenantId, helloResult.isHouseHoldHelper, helloResult.isEmployee);
            setUserFeedBack("Registration succeeded");
            history.push('');
        }
        catch (error){
            setUserFeedBack('Registration failed, ask your company to send a new invitation')
        }
    } 


    useEffect(()=> {
        setUserFeedBack("Registering");

        if (userInfo.isRegistered) {
            console.info('The user is already registered, redirecting to the home page'); 
            history.push(''); // todo - this might be an invitation for an additional role;  but for now, this is ok
            setUserFeedBack("User is already registered");
        }
        
        var params = new URLSearchParams(location.search);
        let registrationToken = params.get("token");
        
        if (registrationToken === null || registrationToken === undefined || registrationToken?.length==0) {
            setUserFeedBack("Registration failed - invalid link.");
            return;
        }
        //go ahead and do our authenticated call
        fetchData(registrationToken);

    }, []);
  
    return (
        <div>
             <h1>AutoRegister</h1> 
             <div>{userFeedBack}</div>
        </div>
    );
}

export default Page_AutoRegisterUser;