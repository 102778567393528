import React, { useContext } from 'react';
import {UserContext}  from './UserProvider';
import { RouteDefines } from './routes';
import {useHistory, useLocation} from 'react-router-dom'


const Page_Account = () => {
    const history = useHistory();
    const userInfo = useContext(UserContext);


    if (!userInfo.isRegistered || !userInfo.loggedIn) {
        return <div>User should be registered first.</div>
    }
  
    return (
        <div>
            <i>Welcome {userInfo.userName}</i>.    
            <br/><br/><br/>
            <div>TenantId: {userInfo.currentTenandId}</div>
        </div>
    );
}

export default Page_Account;