import { Session } from 'inspector';
import React, { useState, useEffect  } from 'react';
import {sbGetTimePart, sbGetDatePart, sbWeekDayPart} from './DateFormatter'
import WorkSessionCard, {IWorkSessionCardProps} from './WorkSessionCard';

export interface IWorkSessionCalenderProps {
    workSessions:IWorkSessionCardProps[];
}

const WorkSessionCalender = (props:IWorkSessionCalenderProps) => {

return <div style={{textAlign:"center", width:"300px", display:"inline-block" }}>
          {
            props.workSessions.map((session:IWorkSessionCardProps, index:number)=>
              {
                return <WorkSessionCard key={index} Id={session.Id} From={session.From} To={session.To} Description={session.Description} Street={session.Street} Approved={session.Approved}/>
              }
            )
          }
        </div>


}

export default WorkSessionCalender;