import React, { useState, useEffect  } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import { Console } from 'console';
import  config  from './config'; 
import axios, {AxiosResponse} from 'axios';



const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not validate email!',
  }
};

const Page_Contact = () => {
  const [form] = Form.useForm();  
  useEffect(() => {
        
      return () => {
        
        }
      }, []);

    const [postFeedback, setPostFeedback] = useState('');

    const onFinish = async (values:any) => {
        console.log(values);
        try
        {
          let res = await axios.post(config.backEndApi + '/api/Contact', values);
          setPostFeedback('Uw aanvraag werd verstuurd.');
          form.resetFields();
        }
        catch{
          setPostFeedback('Uw aanvraag kon niet verstuurd worden');
        }
      };

    return (
        <div>
            Deze site werd gemaakt door <i>Kaimonos software development</i>. U kan eventuele vragen/opmerkingen via onderstaand formulier versturen.
            <br></br>
            <br></br>
            <br></br>
            <Form form={form} {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
              <Form.Item name={['contact', 'name']} label="Name" >
                <Input />
              </Form.Item>
              <Form.Item name={['contact', 'email']} label="Email" rules={[{ type: 'email', required: true }]}>
                <Input />
              </Form.Item>
              <Form.Item name={['contact', 'subject']} label="Subject">
                <Input />
              </Form.Item>
              <Form.Item name={['contact', 'message']} label="Message" rules={[{ required: true }]}>
                <Input.TextArea rows={15} />
              </Form.Item>
              <Form.Item name={['contact', 'contact_me_by_fax_only']} label="Fax only" style={{display:'none'}}>
                <Checkbox checked={false} tabIndex={-1} />
              </Form.Item>
              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
            <label>{postFeedback}</label>
        </div>
    );
}

export default Page_Contact;