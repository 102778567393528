import React, { useContext, Key, useState, useEffect } from 'react';
import {UserContext}  from './UserProvider';
import { RouteDefines } from './routes';
import {useHistory, useLocation} from 'react-router-dom'
import {doAuhenticatedTenantGet} from './AxiosWrapper';
import Card_HouseHoldHelper, {DisplayMode} from './Card_HouseHoldHelper'
import { Table, Input, Button, Checkbox, DatePicker, Alert, Dropdown, Menu } from 'antd';

import {TablePaginationConfig} from 'antd/es/table';
import { MenuInfo } from 'rc-menu/lib/interface';

import {IHouseHoldHelper} from './Models/HouseHoldHelper'
import {doAuhenticatedTenantPost} from './AxiosWrapper';





interface tableEntityInterface  extends IHouseHoldHelper
{
    action:string
}

interface aaastate {
    isLoading:boolean;
    datasource:tableEntityInterface[]|undefined;
    pagination:TablePaginationConfig;
}

interface queryParamsForGet {
    results:number;
	page: number
			
}

interface bananaResult {
    totalCount:number;
    data: tableEntityInterface[]
}


const Page_Work_HouseHoldHelper = () => {
    const history = useHistory();
    const userInfo = useContext(UserContext);
    const [tableState, setTableState] = useState<aaastate>({isLoading:true, datasource:undefined, pagination:{pageSize:20, current:1, total:0 }})

    useEffect(()=> {
        getData(tableState.pagination.current as number, tableState.pagination.pageSize as number);
    }, []);


    const getData = async (currentPage:number, pageSize:number)=> {

        let a:queryParamsForGet = 
        {
            results: pageSize,
            page: currentPage
        }
        try {
            let result = await doAuhenticatedTenantGet<bananaResult>(userInfo.currentTenandId, "GetPagedHouseHoldHelpers", a);

            const pager = { ... tableState.pagination };
            
            pager.total = result.totalCount;
            pager.current = currentPage;
            pager.pageSize = pageSize;
            
            setTableState({isLoading:false, datasource:result.data, pagination:pager});
            
        } catch (error) {
            setTableState({...tableState, isLoading:false});
        }
    }

    const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, Key[] | null>) =>
    {
        console.info(pagination);
        console.info();

        getData(pagination.current as number, pagination.pageSize as number);
        
    }
    const sendInviationKey:string = "sendInvitation";
    const planningKey:string = "planning";

    interface InvitationPayload {
        id:number;
    }

    const onSendInvitationActionClicked = async (houseHoldHelper:IHouseHoldHelper) => {
        console.info("sending it", houseHoldHelper);
        let payload:InvitationPayload ={id:houseHoldHelper.id};
        await doAuhenticatedTenantPost<IHouseHoldHelper>(userInfo.currentTenandId, "InviteHouseHoldHelper", payload);

    }

    const getDrowDownMenu = (ent:tableEntityInterface) => {
        const onLocalClick = (info:MenuInfo) :void=> {
            if (info.key == planningKey) {
                history.push(`${RouteDefines.ROUTE_WORK_PLANNER}?householdhelperid=${ent.id}`);
            }
            else if (info.key == sendInviationKey) {
                onSendInvitationActionClicked(ent as IHouseHoldHelper);
            }
        }
        return  <Menu onClick={onLocalClick} >
        <Menu.Item key={planningKey} >
            Planning
        </Menu.Item>
        {ent.emailAddress !== "" && ent.emailAddress !== null && <Menu.Item key={sendInviationKey} >
            Send invitation to {ent?.emailAddress}
        </Menu.Item>}
      </Menu>
    }

    

    if (!userInfo.isRegistered || !userInfo.loggedIn) {
        return <div>User should be registered first.</div>
    }
    if (!userInfo.isEmployee) {
        return <div>User is not an employee.</div>
    }



    

    const columns = [{
        title: 'Id',
        dataIndex: 'id',
        key: 'col_id',
    },
    {
        title: 'First name',
        dataIndex: 'firstName',
        key: 'col_firstName',
    },
    {
        title: 'Last name',
        dataIndex: 'lastName',
        key: 'col_lastName',
    },
    {
        title: 'Email',
        dataIndex: 'emailAddress',
        key: 'col_email',
    },
    {
        title: '',
        key: 'action',
        dataIndex: 'action',
        render: (text:string, ent:tableEntityInterface, index:number)  => (<div>
            <Dropdown overlay={getDrowDownMenu(ent)} placement="bottomLeft" arrow>
                <Button>...</Button>
            </Dropdown>
        </div>
        )
    }
    ];

    return (
        <div>
            <Table<tableEntityInterface>
					columns={columns}
					rowKey={record => record.id}
					dataSource={tableState.datasource}
					pagination={tableState.pagination}
					loading={tableState.isLoading}
                    onChange={handleTableChange}
                    size={"small"}
				/>			
            <Card_HouseHoldHelper DisplayMode={DisplayMode.AddNew}/>

        </div>
    );
}

export default Page_Work_HouseHoldHelper;